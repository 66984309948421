import { decodeEntities } from '../helpers/stringHelpers'
import { isAbsoluteURL } from '../helpers/routeHelpers'
import uniq from 'lodash.uniq'

const initialState = {
  auctions: {},
  auctionsDeprecated: null,
  auctionsFeaturedDeprecated: null,
  configurations: {},
  countriesDeprecated: [],
  hasErrors: false,
  historic: [],
  historicFiltered: [],
  historicLoading: false,
  historicSearchTerm: '',
  information: {},
  isLoading: false,
  marketplaceCategoriesDeprecated: null,
  partneredAuctions: [],
  registeredAuctionsDeprecated: {}
}

const collatorCompare = Intl.Collator('en', { numeric: true }).compare
const sortByLotNumber = (a, b) => collatorCompare(a.lotNumber, b.lotNumber)

export default function auctions (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case 'AUCTION_GET':
    case 'AUCTIONS_DEPRECATED_GET':
      return Object.assign({}, state, {
        hasErrors: false,
        isLoading: true
      })

    case 'AUCTION_GET_SUCCESS': {
      const existingAuction = state.auctions[action.auction.uuid]

      return (existingAuction && action.timestamp < existingAuction.timestamp)
        ? state
        : Object.assign({}, state, {
          auctions: Object.assign({}, state.auctions, {
            [action.auction.uuid]: Object.assign({}, action.auction, {
              title: decodeEntities(action.auction.title)
            })
          }),
          hasErrors: false,
          isLoading: false,
          partneredAuctions: action.partneredAuctions || []
        })
    }

    case 'AUCTION_LISTINGS_ADD_SUCCESS': {
      const uuid = action.auctionUuid
      return state.auctions[uuid]
        ? Object.assign({}, state, {
          auctions: Object.assign({}, state.auctions, {
            [uuid]: Object.assign({}, state.auctions[uuid], {
              activeListings: uniq(state.auctions[uuid].activeListings.concat(action.listingUuids))
                .map(listingUuid => action.listings[listingUuid])
                .filter(listing => !!listing)
                .sort(sortByLotNumber)
                .map(listing => listing.uuid)
            })
          })
        })
        : state
    }

    case 'AUCTION_LISTINGS_REMOVE_SUCCESS': {
      const uuid = action.auctionUuid
      return state.auctions[uuid]
        ? Object.assign({}, state, {
          auctions: Object.assign({}, state.auctions, {
            [uuid]: Object.assign({}, state.auctions[uuid], {
              activeListings: state.auctions[uuid].activeListings
                .filter(listingUuid => !action.listingUuids.includes(listingUuid))
            })
          })
        })
        : state
    }

    case 'AUCTION_GET_FAILED':
    case 'AUCTIONS_DEPRECATED_GET_FAILED':
      return Object.assign({}, state, {
        hasErrors: true,
        isLoading: false
      })

    case 'AUCTIONS_DEPRECATED_GET_SUCCESS':
      return Object.assign({}, state, {
        auctionsDeprecated: action.auctions,
        auctionsFeaturedDeprecated: action.featuredAuctions,
        countriesDeprecated: action.countries,
        hasErrors: false,
        isLoading: false,
        marketplaceCategoriesDeprecated: action.marketplaceCategories,
        registeredAuctionsDeprecated: action.registeredAuctions
      })

    case 'AUCTIONS_HISTORIC_GET':
      return Object.assign({}, state, {
        hasErrors: false,
        historicLoading: true
      })

    case 'AUCTIONS_HISTORIC_GET_SUCCESS':
      return Object.assign({}, state, {
        hasErrors: false,
        historic: action.auctions,
        historicFiltered: (state.historicSearchTerm) ? action.auctions.filter((historicAuction) => historicAuction.title.toLowerCase().includes(state.historicSearchTerm.toLowerCase())) : action.auctions,
        historicLoading: false
      })

    case 'AUCTIONS_HISTORIC_GET_FAILED':
      return Object.assign({}, state, {
        hasErrors: true,
        historicLoading: false
      })

    case 'AUCTIONS_HISTORIC_SEARCH':
      return Object.assign({}, state, {
        historicSearchTerm: action.term,
        historicFiltered: state.historic.filter((historicAuction) => historicAuction.title.toLowerCase().includes(action.term.toLowerCase()))
      })

    case 'AUCTION_INFO_GET_SUCCESS': {
      const catalogueUrl = action.information.catalogueUrl
        ? isAbsoluteURL(action.information.catalogueUrl)
          ? action.information.catalogueUrl
          : `//${action.information.catalogueUrl}`
        : null

      return Object.assign({}, state, {
        information: Object.assign({}, state.information, {
          [action.auctionUuid]: Object.assign({}, action.information, {
            address: decodeEntities(action.information.address),
            catalogueUrl,
            clearanceInstructions: decodeEntities(action.information.clearanceInstructions),
            collectionInformation: decodeEntities(action.information.collectionInformation),
            description: decodeEntities(action.information.description),
            paymentInformation: decodeEntities(action.information.paymentInformation),
            removalInformation: decodeEntities(action.information.removalInformation),
            termsAndConditionsInformation: decodeEntities(action.information.termsAndConditionsInformation),
            viewingInstruction: decodeEntities(action.information.viewingInstruction)
          })
        })
      })
    }

    case 'AUCTION_CONFIG_GET_SUCCESS':
      return Object.assign({}, state, {
        configurations: Object.assign({}, state.configuration, {
          [action.auctionUuid]: action.configuration
        })
      })

    default:
      return state
  }
}
