const initialState = {
  auction: {},
  global: []
}

const hideByKey = (notificationsToIterate, key) => notificationsToIterate
  ? notificationsToIterate.map(notification => notification.key === key
    ? Object.assign({}, notification, {
      visible: false
    })
    : notification)
  : []

export default function notifications (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case 'AUCTION_NOTIFICATION_ADDED': {
      const notification = {
        listingUuid: action.listingUuid,
        icon: action.icon,
        message: action.message,
        type: action.notificationType,
        visible: true,
        key: `${action.listingUuid}-${Date.now()}`
      }
      return Object.assign({}, state, {
        auction: Object.assign({}, state.auction, {
          [action.auctionUuid]: state.auction[action.auctionUuid]
            ? [notification, ...state.auction[action.auctionUuid]]
            : [notification]
        })
      })
    }

    case 'AUCTION_NOTIFICATION_REMOVED':
      return Object.assign({}, state, {
        auction: Object.assign({}, state.auction, {
          [action.auctionUuid]: hideByKey(
            state.auction[action.auctionUuid],
            action.key
          )
        })
      })

    case 'GLOBAL_NOTIFICATION_ADDED': {
      const notification = {
        icon: action.icon,
        message: action.message,
        type: action.notificationType,
        pageNeedsRefreshing: action.pageNeedsRefreshing,
        visible: true,
        key: Date.now()
      }
      return Object.assign({}, state, {
        global: [notification, ...state.global]
      })
    }
    case 'GLOBAL_NOTIFICATION_REMOVED':
      return Object.assign({}, state, {
        global: hideByKey(state.global, action.key)
      })

    default:
      return state
  }
}
