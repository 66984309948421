import APIRequest from '../helpers/apiHelpers'
import Lot from '../models/lot.model'
import VendorStats from '../models/vendorStats.model'
import { decodeEntities } from '../helpers/stringHelpers'

const sales = {
  lotListDeprecated: (auctionId) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'LOTS_LIST_DEPRECATED'
      })
      return APIRequest.get(apiBase, clientId, 'mySalesAuctionItems', `auctionId=${auctionId}`)
        .then(res => {
          if (typeof res.body.vendorModels !== 'object' || !res.body.vendorModels.length) throw new Error('Error fetching Lot Data')

          // extract lots into objects for redux store
          const lotIdsByVendor = {}
          const lots = {}
          for (const vendor of res.body.vendorModels) {
            lotIdsByVendor[vendor.id] = []
            for (const lot of vendor.listOfItems) {
              if (lot.lotNumber) {
                const normalisedLot = Object.assign({}, lot, {
                  status: lot.statusId
                })

                lots[lot.lotNumber] = new Lot(normalisedLot).toJSON()

                lotIdsByVendor[vendor.id].push(lot.lotNumber)
              }
            }
          }

          const result = dispatch({
            type: 'LOTS_LIST_DEPRECATED_SUCCESS',
            lots
          })

          dispatch({
            type: 'VENDOR_LOTS_LIST_SUCCESS',
            lotIdsByVendor
          })

          return result
        })
        .catch(err => {
          console.error(err)
          dispatch({
            type: 'LOTS_LIST_DEPRECATED_FAILED',
            err
          })
          throw err
        })
    }
  },
  lotSearch: (searchTerm) => async dispatch => dispatch({
    type: 'LOT_SEARCH',
    searchTerm
  }),
  lotStateFilter: (statusId) => async dispatch => dispatch({
    type: 'LOT_STATE_FILTER',
    statusId
  }),
  statsGet: (auctionId, isSilent = false) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'SALE_STATS_GET',
        isSilent
      })
      return APIRequest.get(apiBase, clientId, 'mySalesAuctionGraphs', `auctionId=${auctionId}`)
        .then(res => {
          const totalVisits = (typeof res.body.totalVisits === 'number') ? res.body.totalVisits.toLocaleString() : `${res.body.totalVisits}`
          const registrantCount = (typeof res.body.numberRegistrants === 'number') ? res.body.numberRegistrants.toLocaleString() : `${res.body.numberRegistrants}`
          const approvedRegistrantCount = (typeof res.body.numberApprovedRegistrants === 'number') ? res.body.numberApprovedRegistrants.toLocaleString() : `${res.body.numberApprovedRegistrants}`
          // our API refers to United Kingdom as "UK" rather than the correct "GB".
          // This can't be changed within this story due to the far-reaching repurcussions of changing this
          // Therefore this workaround changes it for our needs on the front-end
          const geoChartStats = res.body.countriesChart.map(countryStatistic =>
            (countryStatistic.code === 'UK')
              ? Object.assign({}, countryStatistic, { code: 'GB' })
              : countryStatistic
          )
          const referrerChartStats = res.body.referrersChart
          const visitChartStats = res.body.visitsChart
          return dispatch({
            type: 'SALE_STATS_GET_SUCCESS',
            totalVisits,
            registrantCount,
            approvedRegistrantCount,
            geoChartStats,
            referrerChartStats,
            visitChartStats
          })
        })
        .catch(err => {
          console.error(err)
          dispatch({
            type: 'SALE_STATS_GET_FAILED',
            err
          })
          throw err
        })
    }
  },
  vendorStatsGet: (auctionId, isSilent = false) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'VENDOR_STATS_GET',
        isSilent
      })
      return APIRequest.get(apiBase, clientId, 'mySalesAuction', `auctionId=${auctionId}`)
        .then(res => {
          const auctionType = (res.body.webcastFlag) ? 'webcast' : 'timed'
          if (typeof res.body.vendorModels !== 'object' || !res.body.vendorModels.length) throw new Error('Error fetching Auction Data')
          const vendorStats = res.body.vendorModels.map(vendorStatsModel => new VendorStats(vendorStatsModel, res.body.webcastFlag).toJSON())
          return dispatch({
            type: 'VENDOR_STATS_GET_SUCCESS',
            auctionType,
            title: decodeEntities(res.body.title),
            vendorStats
          })
        })
        .catch(err => {
          console.error(err)
          dispatch({
            type: 'VENDOR_STATS_GET_FAILED',
            err
          })
          throw err
        })
    }
  }
}

export default sales
