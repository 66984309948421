import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from '../modal'
import ModalBody from '../modalBody'
import Button from '../button'
import ModalFooter from '../modalFooter'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'

const LostConnectionModal = ({ isOpen, message }) => {
  return (
    <Modal
      backdrop={'static'}
      aria-label="Refresh page"
      isOpen={isOpen}
      className="text-center"
    >
      <ModalBody aria-labelledby="labeldiv">
        <FontAwesomeIcon
          icon={faCircleExclamation}
          className={'modal__icon modal__icon--danger'}
        />
        <p className="modal__message">{message}</p>
      </ModalBody>
      <ModalFooter>
        <Button
          active={true}
          block={true}
          onClick={() => window.location.reload()}
          color="default"
          className="modal__cta--reload"
        >
          REFRESH
        </Button>
      </ModalFooter>
    </Modal>
  )
}
LostConnectionModal.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string
}
export default LostConnectionModal
