import APIRequest from '../helpers/apiHelpers'
import { toast } from 'react-toastify'
import { decodeEntities } from '../helpers/stringHelpers'
import get from 'lodash.get'

const parseMaxBid = (maxBid) => (Object.assign({}, maxBid, {
  value: maxBid.value / 100
}))

const listingSales = {
  getIncrements: (listingUuid) => {
    return async (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'LISTING_SALE_GET_INCREMENTS'
      })
      try {
        const res = await APIRequest.get(apiBase, clientId, `bid/increments/${listingUuid}`)
        const increments = {}
        // divide by 100, due to it always being in pence
        for (const key in res.body.increments) {
          if (Object.hasOwnProperty.call(res.body.increments, key)) {
            increments[key / 100] = res.body.increments[key] / 100
          }
        }
        return dispatch({
          type: 'LISTING_SALE_GET_INCREMENTS_SUCCESS',
          increments,
          listingUuid
        })
      } catch (err) {
        console.error(err)
        dispatch({
          type: 'LISTING_SALE_GET_INCREMENTS_FAILED',
          err
        })
        throw err
      }
    }
  },
  onMouseOver: (auctionId, isMouseOverButton, listingId, uid) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'LOT_SALE_MOUSEOVER'
      })
      return APIRequest.post(apiBase, clientId, `webcast2/mouseOverBid/${auctionId}/${listingId}/${isMouseOverButton}?uid=${uid}`)
        .then(res => {
          if (res.body.worked) {
            return dispatch({
              type: 'LOT_SALE_MOUSEOVER_SUCCESS',
              isMouseOverButton
            })
          }
          throw new Error(res.body.message)
        })
        .catch(err => {
          console.error(err)
          dispatch({
            type: 'LOT_SALE_MOUSEOVER_FAILED',
            err
          })
          throw err
        })
    }
  },
  placeAutoBid: (listingUuid, listingId, registrantId, userUuid, userId, autoBidValue, isWebcast = false) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'MAX_BID_SET'
      })
      const amount = isWebcast ? autoBidValue * 100 : autoBidValue
      return APIRequest.post(apiBase, clientId, `timed/autobid/${listingId}/${registrantId}/${userId}/${amount}`)
        .then(res => {
          if (res.body.worked && !res.body.models.ErrorModel) {
            return dispatch({
              type: 'MAX_BID_SET_SUCCESS',
              maxBid: {
                listingUuid,
                userUuid,
                uuid: get(res.body, 'models.TimedUserInfoModel.highestAutoBidUuid'),
                value: autoBidValue
              }
            })
          }
          const errorMessage = res.body.models?.ErrorModel?.message || res.body.message
          throw new Error(errorMessage)
        })
        .catch(err => {
          console.error(err)
          dispatch({
            type: 'MAX_BID_SET_FAILED',
            err
          })
          let errorMessage
          try {
            errorMessage = JSON.parse(err.message).message
          } catch (e) {
            errorMessage = err.message
          }
          toast.error(decodeEntities(errorMessage), {
            autoClose: 10000
          })
          throw err
        })
    }
  },
  placeBid: (auctionId, registrantId, listingId, bidValue, uid) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'LOT_SALE_BID'
      })
      return APIRequest.post(apiBase, clientId, `webcast2/placeBid/${auctionId}/${listingId}/${registrantId}/${bidValue * 100}?uid=${uid}`, {}, 2)
        .then(res => {
          if (res?.body?.worked) {
            return dispatch({
              type: 'LOT_SALE_BID_SUCCESS'
            })
          }
          dispatch({
            type: 'LOT_SALE_BID_FAILED',
            err: new Error(res.body.message)
          })
          toast.warning(res.body.message, {
            autoClose: 10000
          })
        })
        .catch(err => {
          console.error(err)
          dispatch({
            type: 'LOT_SALE_BID_FAILED',
            err
          })
          let errorMessage
          try {
            errorMessage = JSON.parse(err.message).message
          } catch (e) {
            errorMessage = err.message
          }
          toast.error(decodeEntities(errorMessage), {
            autoClose: 10000
          })
          throw err
        })
    }
  },
  placeBuy: (listingUuid, listingId, registrantId, userUuid, userId, purchaseValue) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'OFFER_SET'
      })
      return APIRequest.post(apiBase, clientId, 'timed/buyNow', {
        '@class': 'com.bidlogix.scaffold.models.bidding.BuyNowRequestModel',
        itemId: listingId,
        registrantId,
        amount: purchaseValue,
        userId
      })
        .then(res => {
          if (res.body.worked && !get(res.body, 'models.ErrorModel.failed')) {
            const rejected = get(res.body, 'models.BiddingItemModel.timedUserInfo.offerModels[0].statusName') === 'rejected'

            return dispatch({
              type: 'OFFER_SET_SUCCESS',
              listingUuid,
              offer: {
                userUuid,
                uuid: get(res.body, 'models.BiddingItemModel.timedUserInfo.highestOfferUuid'),
                amount: purchaseValue,
                rejected
              }
            })
          }
          const errorMessage = res.body.message || get(res.body, 'models.ErrorModel.message')
          dispatch({
            type: 'OFFER_SET_FAILED',
            err: new Error(errorMessage)
          })
          toast.warning(errorMessage, {
            autoClose: 10000
          })
        })
        .catch(err => {
          console.error(err)
          dispatch({
            type: 'OFFER_SET_FAILED',
            err
          })
          let errorMessage
          try {
            errorMessage = JSON.parse(err.message).message
          } catch (e) {
            errorMessage = err.message
          }
          toast.error(decodeEntities(errorMessage), {
            autoClose: 10000
          })
          throw err
        })
    }
  },
  placeOffer: (listingUuid, listingId, registrantId, userUuid, userId, offerValue, isTender = false) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'OFFER_SET'
      })
      return APIRequest.post(apiBase, clientId, isTender ? 'timed/tender' : 'timed/makeOffer', {
        '@class': 'com.bidlogix.scaffold.models.bidding.BuyNowRequestModel',
        itemId: listingId,
        registrantId,
        amount: offerValue,
        userId
      })
        .then(res => {
          if (res.body.worked && !get(res.body, 'models.ErrorModel.failed')) {
            const rejected = get(res.body, 'models.BiddingItemModel.timedUserInfo.offerModels[0].statusName') === 'rejected'

            return dispatch({
              type: 'OFFER_SET_SUCCESS',
              listingUuid,
              offer: {
                userUuid,
                uuid: get(res.body, 'models.BiddingItemModel.timedUserInfo.highestOfferUuid'),
                amount: offerValue,
                rejected
              }
            })
          }
          const errorMessage = res.body.message || get(res.body, 'models.ErrorModel.message')
          dispatch({
            type: 'OFFER_SET_FAILED',
            err: new Error(errorMessage)
          })
          toast.warning(errorMessage, {
            autoClose: 10000
          })
        })
        .catch(err => {
          console.error(err)
          dispatch({
            type: 'OFFER_SET_FAILED',
            err
          })
          let errorMessage
          try {
            errorMessage = JSON.parse(err.message).message
          } catch (e) {
            errorMessage = err.message
          }
          toast.error(decodeEntities(errorMessage), {
            autoClose: 10000
          })
          throw err
        })
    }
  },
  placeTimedBid: (listingId, registrantId, userId, bidValue) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'LOT_SALE_BID'
      })
      return APIRequest.post(apiBase, clientId, `timed/bid/${listingId}/${registrantId}/${userId}/${bidValue}`)
        .then(res => {
          if (res.body.worked && !res.body.models.ErrorModel) {
            return dispatch({
              type: 'LOT_SALE_BID_SUCCESS'
            })
          }
          const errorMessage = get(res.body, 'models.ErrorModel.message', res.body.message)
          throw new Error(errorMessage)
        })
        .catch(err => {
          console.error(err)
          dispatch({
            type: 'LOT_SALE_BID_FAILED',
            err
          })
          let errorMessage
          try {
            errorMessage = JSON.parse(err.message).message
          } catch (e) {
            errorMessage = err.message
          }
          toast.warning(decodeEntities(errorMessage), {
            autoClose: 10000
          })
          throw err
        })
    }
  },
  placeMaxBid: (auctionId, registrantId, listingId, bidValue, uid) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'MAX_BID_SET'
      })
      return APIRequest.post(apiBase, clientId, `webcast2/placeMaxBid/${auctionId}/${listingId}/${registrantId}/${bidValue * 100}?uid=${uid}`, {}, 2)
        .then(res => {
          if (res.body.worked !== false && !res.body.status) {
            return dispatch({
              type: 'MAX_BID_SET_SUCCESS',
              maxBid: parseMaxBid(res.body),
              message: res.body.message.replace('-', '_')
            })
          }
          dispatch({
            type: 'MAX_BID_SET_FAILED',
            err: new Error(res.body.message)
          })
          toast.warning(res.body.message, {
            autoClose: 10000
          })
        })
        .catch(err => {
          console.error(err)
          dispatch({
            type: 'MAX_BID_SET_FAILED',
            err
          })
          let errorMessage
          try {
            errorMessage = JSON.parse(err.message).message
          } catch (e) {
            errorMessage = err.message
          }
          toast.error(decodeEntities(errorMessage), {
            autoClose: 10000
          })
          throw err
        })
    }
  }
}

export default listingSales
