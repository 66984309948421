import React, { Component, Suspense } from 'react'
import { composeWithDevTools } from 'redux-devtools-extension'
import merge from 'lodash.merge'
import mergeWith from 'lodash.mergewith'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { ToastContainer } from 'react-toastify'
import 'react-dates/initialize'
import * as Sentry from '@sentry/react'
import PropTypes from 'prop-types'

import reducers from './reducers'
import Routes from './routes'
import Loading from './components/loading'
import ErrorBoundary from './components/errorBoundary'
import WithBaseModels from './modules/withBaseModels'
import { config } from './actions'

import './styles/bootstrap-STYLE_VERSION/app.scss'
import GlobalNotifications from './components/globalNotifications'

const optDefaults = {
  config: {
    isStaging: false
  },
  modules: {
    auctionArchived: true,
    auctionDetails: true,
    invoices: true,
    login: true,
    lotDetails: '#!/auctions/%AUCTION_ID%/listings/%ITEM_ID%',
    mySales: true,
    register: true,
    webcast: true
  },
  options: {
    allowTitleChange: true,
    allowMetaDescriptionChange: true,
    defaultModule: null,
    disableBranding: false,
    enablePaginationTop: false,
    itemsPerPageDefault: 30,
    itemsPerPageOptions: [18, 24, 30, 48, 60, 90],
    socialLinks: {
      email: true,
      messenger: true,
      pinterest: true,
      facebook: true,
      twitter: true,
      linkedin: true,
      whatsapp: true,
      instapaper: false,
      line: false,
      livejournal: false,
      mailru: false,
      ok: false,
      pocket: false,
      reddit: false,
      telegram: false,
      tumblr: false,
      viber: false,
      vk: false,
      weibo: false,
      workplace: false
    },
    urlBaseName: '/',
    urlPattern: 'hash'
  }
}

const inputOpts = window.bidjs
if (!inputOpts || !inputOpts.config) throw new Error('No BidJS configuration was found. Please add configuration as described in the documentation at https://docs.bidjs.com')
const requiredConfigKeys = ['clientId', 'region', 'server']
const missingConfigKeys = []
for (const key of requiredConfigKeys) {
  if (!inputOpts.config[key]) {
    missingConfigKeys.push(key)
  }
}
if (missingConfigKeys.length) {
  throw new Error(`BidJS configuration missing key(s): ${missingConfigKeys.toString()}`)
}
const opts = mergeWith(optDefaults, inputOpts, (objValue, srcValue) => {
  const result = merge(objValue, srcValue)
  if (srcValue.itemsPerPageOptions) result.itemsPerPageOptions = srcValue.itemsPerPageOptions
  return result
})

const serverSuffix = `${opts.config.region}${opts.config.isStaging && opts.config.region !== 'local' ? '.staging' : ''}.bidjs.com`
opts.config.apiBase = `https://${opts.config.server}.${serverSuffix}/auction-007/api`
opts.config.websocketBase = `wss://${opts.config.isStaging ? `${process.env.REACT_APP_BROADCAST_BRANCH || 'develop'}-` : ''}broadcast.${opts.config.region === 'local' ? 'eu-west-2' : opts.config.region}${opts.config.isStaging ? '.staging' : ''}.bidjs.com`

if (!window.bidjsHooks) {
  window.bidjsHooks = {}
}

Sentry.setTag('clientId', opts.config.clientId)

const initialState = {
  config: {
    hasErrors: false,
    input: opts
  }
}

const composedEnhancers = composeWithDevTools(
  applyMiddleware(thunk)
)

const store = createStore(reducers, initialState, composedEnhancers)

class App extends Component {
  constructor () {
    super()
    window.bidjsHooks.forceBaseModelReload = () => {
      store.dispatch(config.getBaseModels())
    }
    if (window.bidjs?.callback) {
      window.bidjs.callback({
        action: 'BIDJS_MODULES_INITIALISED'
      })
    }
  }

  render () {
    return (
      <Suspense
        fallback={
          <div className="page__loader">
            <Loading />
          </div>
        }
      >
        <Provider store={store}>
          <ToastContainer />
          <WithBaseModels>
            <ErrorBoundary>
              <Routes
                analyticsNamespace={
                  opts.options.analyticsCode
                    ? opts.options.analyticsNamespace || ''
                    : null
                }
                defaultModule={opts.options.defaultModule}
                history={this.props.history}
                urlBaseName={opts.options.urlBaseName}
                urlPattern={opts.options.urlPattern}
              />
              <GlobalNotifications />
            </ErrorBoundary>
          </WithBaseModels>
        </Provider>
      </Suspense>
    )
  }
}

App.propTypes = {
  history: PropTypes.object.isRequired
}

export default App
