import APIRequest from '../helpers/apiHelpers'
import { decodeEntities } from '../helpers/stringHelpers'
import ReferenceData from '../models/referenceData.model'
import User from '../models/user.model'
import UserAccount from '../models/userAccount.model'
import { toast } from 'react-toastify'
import get from 'lodash.get'
import authHelpers from '../helpers/authHelpers'

const users = {
  activateAccount: (key, id) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'USER_ACCOUNT_ACTIVATE'
      })
      return APIRequest.get(apiBase, clientId, `confirmEmail?id=${id}&key=${key}`)
        .then(() => dispatch({
          type: 'USER_ACCOUNT_ACTIVATE_SUCCESS'
        }))
        .catch(err => {
          console.error(err)
          toast.error(decodeEntities(err.message), {
            autoClose: false
          })
          dispatch({
            type: 'USER_ACCOUNT_ACTIVATE_FAILED',
            err
          })
          throw err
        })
    }
  },
  changePassword: (id, token, password, passwordRepeat) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'USER_PASSWORD_RESET'
      })
      return APIRequest.put(apiBase, clientId, 'changePassword', {
        '@class': 'com.bidlogix.scaffold.models.account.ChangePasswordModel',
        id,
        password,
        passwordConfirm: passwordRepeat,
        token
      })
        .then(() => {
          return dispatch({
            type: 'USER_PASSWORD_RESET_SUCCESS'
          })
        })
        .catch(err => {
          console.error(err.response)
          const message = get(err, 'response.body.message', err.message)
          toast.error(decodeEntities(message), {
            autoClose: false
          })
          dispatch({
            type: 'USER_PASSWORD_RESET_FAILED',
            err
          })
          throw err
        })
    }
  },
  getExistingAccount: () => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'USER_ACCOUNT_GET'
      })
      return APIRequest.get(apiBase, clientId, 'mySettings')
        .then(res => dispatch({
          type: 'USER_ACCOUNT_GET_SUCCESS',
          referenceData: new ReferenceData(res.body.referenceData).toJSON(),
          userAccount: new UserAccount(res.body.accountManagementFormModel).toJSON()
        }))
        .catch(err => {
          console.error(err)
          toast.error(decodeEntities(err.message), {
            autoClose: false
          })
          dispatch({
            type: 'USER_ACCOUNT_GET_FAILED',
            err
          })
          throw err
        })
    }
  },
  getNewAccount: () => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'USER_ACCOUNT_GET'
      })
      return APIRequest.get(apiBase, clientId, 'createAccount')
        .then(res => dispatch({
          type: 'USER_ACCOUNT_GET_SUCCESS',
          referenceData: new ReferenceData(res.body.referenceData).toJSON(),
          userAccount: new UserAccount(res.body.accountManagementFormModel).toJSON()
        }))
        .catch(err => {
          console.error(err)
          toast.error(decodeEntities(err.message), {
            autoClose: false
          })
          dispatch({
            type: 'USER_ACCOUNT_GET_FAILED',
            err
          })
          throw err
        })
    }
  },
  getOAuthToken: (thirdPartyToken) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'USER_OAUTH_TOKEN_GET'
      })
      return APIRequest.post(apiBase, clientId, 'ssologin', {
        token: thirdPartyToken
      })
        .then(res => dispatch({
          type: 'USER_OAUTH_TOKEN_GET_SUCCESS',
          token: res.body.accessToken
        }))
        .catch(err => {
          console.error(err.response)
          const message = get(err, 'response.body.message', err.message)
          toast.error(decodeEntities(message))
          dispatch({
            type: 'USER_OAUTH_TOKEN_GET_FAILED',
            err
          })
          throw err
        })
    }
  },
  getPasswordResetToken: (key, id) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'USER_PASSWORD_RESET_TOKEN_GET'
      })
      return APIRequest.get(apiBase, clientId, `changePassword?id=${id}&key=${key}`)
        .then(res => {
          const body = res.body
          return dispatch({
            type: 'USER_PASSWORD_RESET_TOKEN_GET_SUCCESS',
            id: body.id,
            token: body.token,
            isValid: body.valid
          })
        })
        .catch(err => {
          console.error(err.response)
          const message = get(err, 'response.body.message', err.message)
          toast.error(decodeEntities(message), {
            autoClose: false
          })
          dispatch({
            type: 'USER_PASSWORD_RESET_TOKEN_GET_FAILED',
            err
          })
          throw err
        })
    }
  },
  login: (identifier, password) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'USER_LOGIN'
      })
      return APIRequest.post(apiBase, clientId, 'token', {
        '@class': 'com.bidlogix.scaffold.models.user.LoginModel',
        username: identifier,
        password
      })
        .then(res => {
          const token = res.body.accessToken
          if (token) {
            authHelpers.handleAuthentication(token)
          }

          const user = get(res.body, 'userModel')
          if (user) {
            dispatch({
              type: 'USER_MODEL_GET_SUCCESS',
              user: new User(user).toJSON()
            })
          }
          return dispatch({
            type: 'USER_LOGIN_SUCCESS'
          })
        })
        .catch(err => {
          dispatch({
            type: 'USER_LOGIN_FAILED',
            err
          })
          throw err
        })
    }
  },
  logout: () => {
    return dispatch => {
      dispatch({
        type: 'USER_LOGOUT'
      })
      authHelpers.handleUnauthentication()
      dispatch({
        type: 'MAX_BIDS_RESET'
      })
      dispatch({
        type: 'OFFERS_RESET'
      })
      return dispatch({
        type: 'USER_LOGOUT_SUCCESS'
      })
    }
  },
  patchAccount: (uid, userAccountModel, baseUrl) => {
    const newUserAccountModelAsServerModel = UserAccount.newToServerJSON(userAccountModel)
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'USER_ACCOUNT_UPDATE'
      })
      return APIRequest.post(apiBase, clientId, `mySettings?uid=${uid}&baseUrl=${baseUrl}`, newUserAccountModelAsServerModel)
        .then(res => dispatch({
          type: 'USER_ACCOUNT_UPDATE_SUCCESS',
          data: new UserAccount(res.body.referenceData)
        })
        )
        .catch(err => {
          console.error(err)
          dispatch({
            type: 'USER_ACCOUNT_UPDATE_FAILED',
            err
          })
          throw err
        })
    }
  },
  putAccount: (uid, userAccountModel, baseUrl) => {
    const newUserAccountModelAsServerModel = UserAccount.newToServerJSON(userAccountModel)
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'USER_ACCOUNT_CREATE'
      })
      return APIRequest.post(apiBase, clientId, `createAccount?uid=${uid}&baseUrl=${baseUrl}`, newUserAccountModelAsServerModel)
        .then(res => dispatch({
          type: 'USER_ACCOUNT_CREATE_SUCCESS',
          data: new UserAccount(res.body.referenceData)
        })
        )
        .catch(err => {
          console.error(err)
          dispatch({
            type: 'USER_ACCOUNT_CREATE_FAILED',
            err
          })
          throw err
        })
    }
  },
  submitForgottenPasswordEmail: (uid, email) => {
    return async (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({ type: 'USER_ACCOUNT_REQUEST_FORGOTTEN_PASSWORD' })
      try {
        // we send the current url as baseUrl param so the email knows where to send the user
        await APIRequest.post(apiBase, clientId, `forgottenPassword?baseUrl=${encodeURIComponent(window.location.href)}&uid=${uid}`, {
          email,
          '@class': '.ForgottenPasswordModel',
          resetPasswordMode: false
        })
        return dispatch({ type: 'USER_ACCOUNT_REQUEST_FORGOTTEN_PASSWORD_SUCCESS' })
      } catch (err) {
        console.error(err)
        toast.error(decodeEntities(err.message))
        dispatch({
          type: 'USER_ACCOUNT_REQUEST_FORGOTTEN_PASSWORD_FAILED',
          err
        })
        throw err
      }
    }
  },
  validateAccountField: (uid, fieldKey, fieldValue) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'USER_ACCOUNT_FIELD_VALIDATE',
        fieldKey,
        fieldValue
      })
      return APIRequest.post(apiBase, clientId, `createAccountValidation?uid=${uid}`, {
        label: fieldKey,
        value: fieldValue
      })
        .then(res => {
          if (res.body.errors.length) {
            const errors = res.body.errors.reduce((acc, val) => {
              acc[val.field] = val.code
              return acc
            }, {})
            return dispatch({
              type: 'USER_ACCOUNT_FIELD_VALIDATE_ERRORS',
              fieldKey,
              errors: errors
            })
          }
          return dispatch({
            type: 'USER_ACCOUNT_FIELD_VALIDATE_SUCCESS',
            fieldKey,
            errors: {}
          })
        })
        .catch(err => {
          console.error(err)
          toast.error(decodeEntities(err.message), {
            autoClose: false
          })
          dispatch({
            type: 'USER_ACCOUNT_FIELD_VALIDATE_FAILED',
            err
          })
          throw err
        })
    }
  }
}

export default users
