import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { translate } from '../../helpers/stringHelpers'
import Modal from '../modal'
import ModalBody from '../modalBody'
import Button from '../button'

const ConfirmModal = ({
  buttonText,
  color,
  className,
  handleClose,
  icon,
  id,
  isOpen,
  message
}) => (
  <Modal size='sm' id={id} className={className} isOpen={isOpen} onClose={handleClose}>
    <ModalBody className='text-center'>
      {icon
        ? (
          <FontAwesomeIcon icon={icon} className={`modal__icon modal__icon--${color}`} />
          )
        : null}
      <p className='modal__message' dangerouslySetInnerHTML={{ __html: message }} />
      <Button
        className='modal__cta--close'
        size='sm'
        block
        onClick={handleClose}
      >
        {buttonText || translate('JspPublicCodes_JSP_CONFIRM_MODAL_CLOSE_DEFAULT')}
      </Button>
    </ModalBody>
  </Modal>
)

ConfirmModal.propTypes = {
  buttonText: PropTypes.string,
  color: PropTypes.oneOf(['success', 'warning', 'info', 'danger']),
  className: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  icon: PropTypes.object,
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string
}

ConfirmModal.defaultProps = {
  color: 'success',
  message: ''
}

export default memo(ConfirmModal)
