const initialState = {
  hasErrors: false,
  isLoading: false,
  referenceData: {},
  user: {},
  userAccount: {},
  validationErrors: {}
}

export default function users (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case 'USER_ACCOUNT_GET': {
      return Object.assign({}, state, {
        isLoading: true
      })
    }

    case 'USER_ACCOUNT_GET_SUCCESS': {
      return Object.assign({}, state, {
        referenceData: action.referenceData,
        isLoading: false,
        userAccount: action.userAccount
      })
    }

    case 'USER_ACCOUNT_GET_FAILED': {
      return Object.assign({}, state, {
        isLoading: false
      })
    }

    case 'USER_ACCOUNT_FIELD_VALIDATE_ERRORS':
      return Object.assign({}, state, {
        validationErrors: Object.assign({}, state.validationErrors, action.errors)
      })

    case 'USER_ACCOUNT_FIELD_VALIDATE_SUCCESS': {
      const hasExistingErrorsForKey = !!state.validationErrors[action.fieldKey]
      const clonedState = Object.assign({}, state, {
        validationErrors: Object.assign({}, state.validationErrors)
      })
      if (hasExistingErrorsForKey) {
        delete clonedState.validationErrors[action.fieldKey]
      }
      return clonedState
    }

    case 'USER_LOGOUT_SUCCESS': {
      return Object.assign({}, state, {
        user: {},
        userAccount: {}
      })
    }

    case 'USER_MODEL_GET_SUCCESS':
      return Object.assign({}, state, {
        user: action.user
      })

    default:
      return state
  }
}
