import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import { notifications } from '../../actions'
import ConfirmModal from '../../components/confirmModal'
import LostConnectionModal from '../lostConnectionModal'

export class GlobalNotifications extends Component {
  render () {
    return this.props.globalNotifications.map((notification) => (
      <>{notification.pageNeedsRefreshing
        ? <LostConnectionModal
          isOpen={notification.visible}
          message={notification.message}
          />
        : <ConfirmModal
          key={`notification-${notification.key}`}
          color={notification.type}
          handleClose={() =>
            this.props.handleGlobalNotificationClear(notification.key)
          }
          icon={notification.icon || faCheckCircle}
          className="confirmation-modal--global"
          id={`confirmation-modal--${notification.key}`}
          isOpen={notification.visible}
          message={notification.message}
        />
        }
      </>
    ))
  }
}

GlobalNotifications.propTypes = {
  globalNotifications: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType(PropTypes.string, PropTypes.number).isRequired,
      type: PropTypes.string,
      message: PropTypes.string.isRequired,
      visible: PropTypes.bool.isRequired,
      pageNeedsRefreshing: PropTypes.bool
    })
  ).isRequired,
  handleGlobalNotificationClear: PropTypes.func.isRequired
}

export function mapStateToProps (state) {
  return {
    globalNotifications: state.notifications.global
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    handleGlobalNotificationClear: (key) => {
      return dispatch(notifications.clearGlobalNotification(key))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalNotifications)
