import { toast } from 'react-toastify'

import APIRequest from '../helpers/apiHelpers'
import { decodeEntities } from '../helpers/stringHelpers'

import RegistrationInformation from '../models/registrationInformation.model'

const registrations = {
  getRegistrationInformation: (auctionUuid) => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'REGISTRATION_INFORMATION_GET'
      })
      return APIRequest.get(apiBase, clientId, `registerForAuction/${auctionUuid}`, null, 2)
        .then(res => dispatch({
          type: 'REGISTRATION_INFORMATION_GET_SUCCESS',
          information: new RegistrationInformation(res.body).toJSON(),
          auctionUuid
        }))
        .catch(err => {
          console.error(err)
          toast.error(decodeEntities(err.message), {
            autoClose: false
          })
          dispatch({
            type: 'REGISTRATION_INFORMATION_GET_FAILED',
            err
          })
          throw err
        })
    }
  },
  putRegistration: (userId, auctionUuid, spendingLimit, referringPage = '/') => {
    return (dispatch, getState) => {
      const { apiBase, clientId } = getState().config.input.config
      dispatch({
        type: 'REGISTRATION_REQUEST'
      })
      const payload = {
        agreedTerms: true,
        auctionUuid,
        referer: referringPage,
        spendingLimit,
        userId
      }

      return APIRequest.post(apiBase, clientId, 'registerForAuction', payload, 2)
        .then(res => {
          const message = (res.body.status === 'APPROVED')
            ? 'JspPublicCodes_JSP_PAGE_AUCTION_REGISTRATION_APPROVED'
            : 'JspPublicCodes_JSP_PAGE_AUCTION_REGISTRATION_PENDING'

          return dispatch({
            type: 'REGISTRATION_REQUEST_SUCCESS',
            auctionUuid,
            message
          })
        })
        .catch(err => {
          console.error(err)
          toast.error(decodeEntities(err.message), {
            autoClose: false
          })
          dispatch({
            type: 'REGISTRATION_REQUEST_FAILED',
            err
          })
          throw err
        })
    }
  }
}

export default registrations
