const initialState = {
  offers: {},
  offersByListing: {}
}

export default function offers (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case 'OFFERS_GET_SUCCESS':
      return Object.assign({}, state, {
        offers: Object.assign({}, state.offers, action.offers),
        offersByListing: Object.assign({}, state.offersByListing, Object.values(action.offers).reduce((acc, val) => {
          if (!acc[val.listingUuid]) {
            acc[val.listingUuid] = []
          }
          acc[val.listingUuid].push(val.uuid)
          return acc
        }, {}))
      })

    case 'OFFER_SET_SUCCESS':
      return Object.assign({}, state, {
        offers: Object.assign({}, state.offers, {
          [action.offer.uuid]: action.offer
        }),
        offersByListing: Object.assign({}, state.offersByListing, {
          [action.listingUuid]: state.offersByListing[action.listingUuid] ? state.offersByListing[action.listingUuid].concat([action.offer.uuid]) : [action.offer.uuid]
        })
      })

    case 'OFFERS_RESET':
      return Object.assign({}, state, {
        offers: {},
        offersByListing: {}
      })

    default:
      return state
  }
}
